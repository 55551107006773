import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import * as CC from "change-case";

export interface SubmitCourierData {
  companyId: string;
  courier: string;
  data: object;
}

export interface SubmitCourierResponse {
  canadaPostTokenId?: string;
}

export const useSubmitCourierMutation = () => {
  return useMutation({
    mutationFn: async ({
      companyId,
      courier,
      data,
    }: SubmitCourierData): Promise<SubmitCourierResponse> => {
      const formattedData: { [k: string]: string } = {};
      Object.keys(data).map((key) => {
        const keyWithNumber = key.split(/(\d+)/).join("_");
        const formattedKey = CC.snakeCase(keyWithNumber);
        return (formattedData[formattedKey] = data[key as keyof typeof data]);
      });

      return await axios.post(
        `${import.meta.env.VITE_API_BASE_URL}/api/v1/companies/${companyId}/courier_accounts/${courier}`,
        { account: formattedData }
      );
    },
  });
};
