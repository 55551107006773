import { useToken } from "@/hooks/useToken";
import { createContext, useContext } from "react";
import Cookies from "js-cookie";
import { Outlet } from "react-router-dom";
import { ErrorPage } from "@/pages/ErrorPage";
import { Pulse } from "easyship-components";

interface AuthContextProps {
  credentials: string | undefined;
}

export const AuthContext = createContext<AuthContextProps>({
  credentials: undefined,
});

export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");
  const credentialsEnv = import.meta.env.VITE_APP_CREDENTIALS;

  let credentials = Cookies.get(credentialsEnv);

  // Check if token is present in the URL, if not check if credentials are stored in the cookies
  const { data, isLoading } = useToken(token || "");

  if (data?.token) {
    credentials && Cookies.remove(credentialsEnv);
    Cookies.set(credentialsEnv, data.token);
    credentials = data.token;
  }

  window.localStorage.setItem("redirectUrl", data?.ESredirectUrl || "");

  if (isLoading) return <Pulse />;
  return (
    <AuthContext.Provider value={{ credentials }}>
      {credentials ? <Outlet /> : <ErrorPage />}
    </AuthContext.Provider>
  );
};
