import { Layout } from "@/components/Layout";
import { useCourierAccount } from "@/hooks/useCourierAccount";
import { routesList } from "@/router/routes-list";
import { useCallback, useEffect, useMemo } from "react";
import { PathParam, useNavigate, useParams } from "react-router-dom";
import { isSingleCountryCourier } from "@/helpers/courierFilters";
import { Pulse } from "easyship-components";
import * as GenericForm from "@/forms/generic/GenericForm";
import * as ApgForm from "@/forms/apg/ApgForm";
import * as AuPostForm from "@/forms/auPost/AuPostForm";
import * as CanadaPostFrom from "@/forms/canadaPost/CanadaPostForm";
import * as DhlForm from "@/forms/dhl/DhlForm";
import * as FedexForm from "@/forms/fedex/FedexForm";
import * as PassportForm from "@/forms/passport/PassportForm";
import * as RoyalMailForm from "@/forms/royalMail/RoyalMailForm";
import * as SendleForm from "@/forms/sendle/SendleForm";
import * as SkypostalForm from "@/forms/skypostal/SkyPostalForm";
import * as UpsForm from "@/forms/ups/UpsForm";
import {
  SubmitCourierData,
  SubmitCourierResponse,
  useSubmitCourierMutation,
} from "@/hooks/useSubmitCourierMutation";
import { useCurrentUser } from "@/hooks/useCurrentUser";
import { courierLogo } from "@/helpers/dataFormattingUtils";
import * as CC from "change-case";
import { postMessage } from "@/helpers/postMessage";
import axios from "axios";
import { useSubmitCanadaPostMutation } from "@/hooks/useSubmitCanadaPostMutation";

const formComponentList = [
  ApgForm,
  AuPostForm,
  CanadaPostFrom,
  DhlForm,
  FedexForm,
  PassportForm,
  RoyalMailForm,
  SendleForm,
  SkypostalForm,
  UpsForm,
];

export const AddCourierPage = (): React.ReactElement => {
  const navigate = useNavigate();
  const { mutate: submitCourier } = useSubmitCourierMutation();
  const { mutate: submitCanadaPost } = useSubmitCanadaPostMutation();
  const { data: currentUser } = useCurrentUser();

  const params =
    useParams<Record<PathParam<typeof routesList.addCourier>, string>>();
  if (!params.id) throw new Error("Missing id param");

  const {
    data: courier,
    isLoading: courierLoading,
    error: courierError,
  } = useCourierAccount(params.id, currentUser?.companyId);

  // If courier connection is successful, redirect to success page or to Courier's website
  const onFormSuccess = (
    data: SubmitCourierResponse,
    variables: SubmitCourierData
  ) => {
    if (variables.courier === "Canada Post") {
      if (!data.canadaPostTokenId) throw new Error("Missing parameters");

      submitCanadaPost({
        id: data.canadaPostTokenId,
        returnUrl: `${import.meta.env.VITE_APP}/redirect`,
      });
    } else {
      postMessage("success", "Courier account added successfully");
      navigate(routesList.success, {
        state: { courier: variables.courier },
      });
    }
  };

  // If courier connection is unsuccessful, redirect to error page
  const onFormError = (error: Error) => {
    const message = axios.isAxiosError(error)
      ? error.response?.data?.status
      : "An error has occurred while adding the courier account";
    postMessage("error", message);
    navigate(routesList.error, {
      state: { message },
    });
  };

  const submitForm = useCallback(
    (data: object) => {
      const companyId = currentUser?.companyId;

      if (!companyId || !courier) throw new Error("Unable to submit");

      submitCourier(
        {
          companyId,
          courier: CC.snakeCase(courier.umbrellaName),
          data: { ...data, originCountryId: params.country_id },
        },
        { onSuccess: onFormSuccess, onError: onFormError }
      );
    },
    [courier]
  );

  const LogoComponent = useMemo<() => React.ReactNode>(() => {
    if (!courier) return () => null;

    return () => (
      <img
        className="h-[44px] rounded"
        src={courierLogo(courier.logoUrl || "")}
      />
    );
  }, [courier]);

  const FormComponent = useMemo<() => React.ReactNode>(() => {
    if (!courier) return () => null;

    const form = formComponentList.find((i) =>
      Object.values(i.COMPATIBLE_UMBRELLA_NAMES).includes(courier.umbrellaName)
    );

    if (!form) {
      return () => (
        <GenericForm.FormComponent
          umbrellaName={courier.umbrellaName}
          onSuccess={submitForm}
        />
      );
    }

    return () => (
      <form.FormComponent
        umbrellaName={courier.umbrellaName}
        onSuccess={submitForm}
      />
    );
  }, [courier, submitForm]);

  // Go to error page if request fails
  useEffect(() => {
    if (courierError) {
      postMessage("error", courierError.message);

      navigate(routesList.error, {
        replace: false,
        state: { message: courierError.message },
      });
    }
  }, [courierError]);

  if (courierLoading) return <Pulse />;

  return (
    <Layout
      leadingElement={<LogoComponent />}
      title={`Connect ${courier?.umbrellaName}`}
    >
      {courierLoading ? (
        <Pulse />
      ) : (
        <>
          <p className="mb-4">
            Authenticate your <strong>{courier?.umbrellaName}</strong>{" "}
            {isSingleCountryCourier(courier?.umbrellaName || "") && (
              <strong>{courier?.alpha2}</strong>
            )}{" "}
            account to start shipping with your own negotiated rates.
          </p>

          <FormComponent />
        </>
      )}
    </Layout>
  );
};
