import { type RouteProps } from "react-router-dom";
import { type RouteIndex } from "./routes-list";
import { RedirectView } from "./Redirect";
import { ErrorPage } from "@/pages/ErrorPage";
import { CourierListPage } from "@/pages/CourierListPage";
import { AddCourierPage } from "@/pages/AddCourier";
import { SuccessPage } from "@/pages/SuccessPage";
import { NotFoundPAge } from "@/pages/NotFoundPage";
import { RedirectPage } from "@/pages/RedirectPage";

type ViewsMap = { [key in RouteIndex]: NonNullable<RouteProps["Component"]> };

export const viewsMap: ViewsMap = {
  home: () => <RedirectView to="courierList" />,
  courierList: () => <CourierListPage />,
  addCourier: () => <AddCourierPage />,
  success: () => <SuccessPage />,
  error: () => <ErrorPage />,
  redirect: () => <RedirectPage />,
  notFound: () => <NotFoundPAge />,
};
