import { FormValues, formSchema } from "./schema";
import { CourierFormComponentProps } from "../types";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form } from "@/components/Form";
import { ControlledInput } from "@/components/ControlledInput";

export const COMPATIBLE_UMBRELLA_NAMES = {
  auPostBusiness: "Australia Post MyPost Business",
  auPostDemand: "Australia Post On Demand",
} satisfies Record<string, string>;

const AU_MYPOST_BUSINESS_REDIRECT =
  "https://auspost.com.au/mypost-business/authorisation/authorize?response_type=code";
const EASYSHIP_CLIENT_ID = "EASYSHIP-7402";
const AU_MYPOST_BUSINESS_STATE = "test1234";
const LYOC_REDIRECT = `${import.meta.env.VITE_APP}/redirect`;

export const FormComponent = ({
  umbrellaName,
  onSuccess,
}: CourierFormComponentProps<FormValues>) => {
  const { control, handleSubmit } = useForm<FormValues>({
    resolver: zodResolver(formSchema),
  });

  const submitForm = async (data: FormValues) => {
    if (umbrellaName === COMPATIBLE_UMBRELLA_NAMES.auPostBusiness) {
      window.localStorage.setItem(
        "myPostBusinessUserAccountName",
        data.nickname
      );

      const url = `${AU_MYPOST_BUSINESS_REDIRECT}&client_id=${EASYSHIP_CLIENT_ID}&redirect_uri=${LYOC_REDIRECT}?umbrella_name=${umbrellaName}&state=${AU_MYPOST_BUSINESS_STATE}`;

      window.open(url, "_self");
    } else {
      onSuccess(data);
    }
  };

  return (
    <Form onSubmit={handleSubmit(submitForm)}>
      {umbrellaName === COMPATIBLE_UMBRELLA_NAMES.auPostBusiness && (
        <ControlledInput
          name="nickname"
          control={control}
          label="Account Name"
          placeholder="e.g. MyCarrierAccount 01"
          statusText="The account name will be used to identify your account"
        />
      )}

      <ControlledInput
        name="accountNumber"
        control={control}
        label="Account Number"
        placeholder="e.g. 123456789"
      />

      <ControlledInput
        name="password"
        control={control}
        label="Password"
        type="password"
      />

      <ControlledInput
        name="userId"
        control={control}
        label="Australia Post API Key"
      />
    </Form>
  );
};
