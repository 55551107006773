import { Option, Pulse, Select, Skeleton } from "easyship-components";
import { useEffect, useMemo, useState } from "react";
import { CourierCard } from "@/components/CourierCard";
import { useCountries } from "@/hooks/useCountries";
import { Layout } from "@/components/Layout";
import { generatePath, useNavigate } from "react-router-dom";
import { routesList } from "@/router/routes-list";
import { useCurrentUser } from "@/hooks/useCurrentUser";
import { Country } from "@/models/Address";
import { Courier } from "@/models/Courier";
import { useCourierAccounts } from "@/hooks/useCourierAccounts";
import {
  courierLogo,
  courierUmbrellaName,
} from "@/helpers/dataFormattingUtils";
import { postMessage } from "@/helpers/postMessage";

export const CourierListPage = () => {
  const navigate = useNavigate();
  const {
    data: countries,
    isLoading: countriesLoading,
    error: countriesError,
  } = useCountries();

  const {
    data: user,
    isLoading: userLoading,
    error: userError,
  } = useCurrentUser();
  const { data: courierAccounts, error: courierAccountsError } =
    useCourierAccounts(user?.companyId);

  const [selectedCountry, setSelectedCountry] = useState<Country | undefined>(
    undefined
  );
  const [isLoadingCouriers, setIsLoadingCouriers] = useState<boolean>(false);
  const handleCountryChange = (option: Option<string> | null) => {
    setSelectedCountry(
      countries?.find(
        (country) => country?.alpha2 === (option as Option<string>).value
      )
    );
  };

  // Show only the countries that the user has access to
  const availableCountries = useMemo(
    () =>
      countries?.filter((country) =>
        user?.shippingCountries.some(
          (sc) => sc["alpha2" as "name"] === country.alpha2
        )
      ) ?? [],
    [countries, user]
  );

  // Show only the couriers that are available in the selected country
  const availableCouriers = useMemo(() => {
    setIsLoadingCouriers(true);

    const filteredCouriers = user?.shippingCountries.find(
      (sc) => sc.alpha2 === selectedCountry?.alpha2
    )?.availableCouriers;

    if (!filteredCouriers || !courierAccounts) return [];

    const couriers = filteredCouriers.map((courier) => {
      const courierData = courierAccounts.find((c) => c.logoUrl === courier);
      return {
        umbrellaName: courierData?.umbrellaName || courierUmbrellaName[courier],
        logoUrl: courierLogo(courierData?.logoUrl || ""),
        id:
          courierData?.id ||
          courierAccounts.find(
            (c) => c.umbrellaName === courierUmbrellaName[courier]
          )?.id ||
          "",
        alpha2: courierData?.alpha2 || "",
      };
    });

    setIsLoadingCouriers(false);

    return couriers;
  }, [courierAccounts, user, selectedCountry]);

  const handleCourierCardClick = (id: string, countryId: string) => {
    navigate(
      generatePath(routesList.addCourier, {
        country_id: countryId,
        id,
      })
    );
  };

  // Go to error page if any request fails
  useEffect(() => {
    if (courierAccountsError || countriesError || userError) {
      const errorMessage =
        courierAccountsError?.message ??
        countriesError?.message ??
        userError?.message;

      postMessage("error", errorMessage);

      navigate(routesList.error, {
        replace: true,
        state: {
          message: errorMessage,
        },
      });
    }
  }, [courierAccountsError, countriesError, userError]);

  return (
    <Layout title="Connect your carrier">
      <p className="mb-5">
        Select the country your carrier account is registered in.
      </p>

      {countriesLoading || userLoading ? (
        <Pulse show={true} />
      ) : (
        <>
          {availableCountries.length > 0 ? (
            <Select
              label="Country"
              onChange={(
                _event: React.ChangeEvent,
                selectedOption: Option<string> | null
              ) => handleCountryChange(selectedOption)}
              options={availableCountries.map((country) => ({
                value: country.alpha2,
                label: country.name,
              }))}
              placeholder="Select a country"
              required={true}
            />
          ) : (
            <p>There are no available countries.</p>
          )}
        </>
      )}

      {selectedCountry && (
        <>
          <p>
            Select the carrier you want to connect with your Easyship account.
          </p>

          {isLoadingCouriers ? (
            Object.keys(Array.from(Array(5))).map((i) => (
              <Skeleton
                key={i}
                height={100}
                width="100%"
                rounded
                className="my-3"
              />
            ))
          ) : (
            <>
              {availableCouriers.map((courier: Courier) => (
                <CourierCard
                  key={courier.id}
                  name={courier.umbrellaName}
                  logo={courier.logoUrl}
                  onClick={() =>
                    handleCourierCardClick(courier.id, selectedCountry.id)
                  }
                />
              ))}

              {availableCouriers?.length === 0 && (
                <p>
                  Sorry, we currently don't support carrier integrations for
                  shipments origination from {selectedCountry.name}
                </p>
              )}
            </>
          )}
        </>
      )}
    </Layout>
  );
};
