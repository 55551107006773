import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { routesList } from "@/router/routes-list";
import { postMessage } from "@/helpers/postMessage";

interface SubmitCanadaPostPayload {
  id: string;
  returnUrl: string;
}

interface SubmitCanadaPostResponse {
  url: string;
  message: string;
}

export const useSubmitCanadaPostMutation = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: async ({
      id,
      returnUrl,
    }: SubmitCanadaPostPayload): Promise<SubmitCanadaPostResponse> => {
      return await axios.post(
        "https://www.canadapost-postescanada.ca/information/app/drc/merchant",
        {
          language: "en-CA",
          "platform-id": "9661954", // ID that assigned by Canada Post to Easyship
          "token-id": id,
          "return-url": returnUrl,
        }
      );
    },
    onError: (error) => {
      const message = axios.isAxiosError(error)
        ? error.response?.data?.status
        : "An error has occurred while adding the courier account";
      postMessage("error", message);
      navigate(routesList.error, {
        state: { message },
      });
    },
  });
};
