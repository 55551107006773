import { useCurrentUser } from "@/hooks/useCurrentUser";
import { useSubmitCourierMutation } from "@/hooks/useSubmitCourierMutation";
import { useUpdateCourierMutation } from "@/hooks/useUpdateCourierMutation";
import { routesList } from "@/router/routes-list";
import axios from "axios";
import { Pulse } from "easyship-components";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const RedirectPage = (): React.ReactElement => {
  const { data: user } = useCurrentUser();
  const navigate = useNavigate();
  const { mutate: submitCourierMutation } = useSubmitCourierMutation();
  const { mutate: updateCourierMutation } = useUpdateCourierMutation();
  const urlParams = new URLSearchParams(window.location.search);
  const redirectUrl = window.localStorage.getItem("ESredirectUrl");
  const isAuPostMyPostBusiness =
    urlParams.get("umbrella_name") === "australia-post-mypost-business";
  const isCanadaPost =
    !!urlParams.get("token-id") && !!urlParams.get("registration-status");

  useEffect(() => {
    if (user?.companyId) {
      if (isCanadaPost) {
        processCanadaPost(user.companyId);
      }

      if (isAuPostMyPostBusiness) {
        processMyPostBusiness(user.companyId);
      }
    } else {
      onError();
    }
  }, [user?.companyId]);

  const onSuccess = (courier: string) => {
    navigate(routesList.success, {
      state: { courier, redirectUrl },
    });
  };

  const onError = (error?: Error) => {
    const message =
      error && axios.isAxiosError(error)
        ? error.response?.data?.status
        : "An error has occurred while adding the courier account";
    navigate(routesList.error, {
      state: { message, redirectUrl, hideButton: true },
    });
  };

  const processMyPostBusiness = async (companyId: string) => {
    const countryDetails = user?.shippingCountries?.find(
      (country) => country.name === "Australia"
    );

    const payload = {
      account: {
        umbrella_name: "australia-post-mypost-business",
        code: urlParams.get("code"),
        nickname:
          urlParams.get("nickname") ??
          window.localStorage.getItem("myPostBusinessUserAccountName"),
        origin_country_id: countryDetails?.id,
      },
    };
    await submitCourierMutation(
      {
        companyId,
        courier: "australia-post-mypost-business",
        data: payload,
      },
      {
        onSuccess: (_, variables) => onSuccess(variables.courier),
        onError,
      }
    );
    window.localStorage.removeItem("myPostBusinessUserAccountName");
  };

  const processCanadaPost = async (companyId: string) => {
    const { courier_id: courierId } = useParams();
    const registrationStatus = urlParams.get("registration-status");
    const tokenId = urlParams.get("token-id");

    if (registrationStatus !== "SUCCESS" || !courierId) {
      navigate(routesList.error, {
        state: { redirectUrl },
      });
      return;
    }

    await updateCourierMutation(
      {
        id: courierId,
        companyId,
        courier: "canada-post",
        data: {
          umbrellaName: "canada-post",
          tokenId,
          registrationStatus,
        },
      },
      {
        onSuccess: (_, variables) => onSuccess(variables.courier),
        onError,
      }
    );
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-sky-300 text-ink-900">
      <Pulse />
      <p>
        Please wait while we setup your courier. This shouldn't take too long.
      </p>
    </div>
  );
};
