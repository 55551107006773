import {
  DefaultError,
  UseMutationResult,
  useMutation,
} from "@tanstack/react-query";
import axios from "axios";
import { Courier } from "@/models/Courier";
import * as CC from "change-case";

interface UpdateCourierAccountData {
  total_couriers_count?: number;
  couriers?: Courier[];
}

export type UseUpdateCourierMutationResult = UseMutationResult<
  UpdateCourierAccountData,
  DefaultError,
  {
    companyId: string;
    courier: string;
    data: object;
    id: string;
  }
>;

export const useUpdateCourierMutation = (): UseUpdateCourierMutationResult => {
  return useMutation<
    UpdateCourierAccountData,
    DefaultError,
    {
      companyId: string;
      courier: string;
      data: object;
      id: string;
    }
  >({
    mutationFn: async ({ companyId, courier, data, id }) => {
      const formattedData: { [k: string]: string } = {};
      Object.keys(data).map((key) => {
        const keyWithNumber = key.split(/(\d+)/).join("_");
        const formattedKey = CC.snakeCase(keyWithNumber);
        return (formattedData[formattedKey] = data[key as keyof typeof data]);
      });
      return await axios.patch(
        `${import.meta.env.VITE_API_BASE_URL}/api/v1/companies/${companyId}/courier_accounts/${courier}/${id}/account`,
        { account: formattedData }
      );
    },
  });
};
